<template>
  <v-container>
    <v-row>
      <v-col>
        <span v-if="tipoNovedad == 'licencia'" class="text-h4"
          >Nueva Licencia</span
        >
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <LicenciaForm></LicenciaForm>
    </div>
  </v-container>
</template>
<script>
import LicenciaForm from "../components/LicenciaForm.vue";

export default {
  name: "NovedadAlta",
  components: {
    LicenciaForm,
  },
  data() {
    return {};
  },
  props: ["tipoNovedad"],
  created() {},
};
</script>
